import React from 'react'
import qiitaImage from "./Images/qiita.png";
import xImage from "./Images/x.jpeg";
import githubImage from "./Images/github.webp";
import instagramImage from "./Images/instagram.webp";
import profileImage from "./Images/profile.jpeg";

const SNSPage = () => {
  return (
    <div className="container text-center">
      <h1>Profile</h1>

      <img src={profileImage} className="profileImage" />

      <p>
        Yusukeです。普段はインフラ系SIerでSEとして働いています。最近はWebエンジニアを目指し個人でWeb系の情報発信やポートフォリオの作成を行っています。主にHTML/CSS/Javascript/Reactメインに取り扱っています。趣味はドライブ、筋トレ、デザイン、プログラミングです。
      </p>

      <section id="skill">
        <div class="text-center">
          <h1 class="title">SNS</h1>
          <div class="row text-center">
            <div class="col-md-4 services">
              <img src={instagramImage} />
              <h4>Instagram</h4>
            </div>
            <div class="col-md-4 services">
              <img src={xImage} />
              <h4>X</h4>
            </div>
            <div class="col-md-4 services">
              <img src={githubImage} />
              <h4>GitHub</h4>
            </div>
            <div class="col-md-4 services">
              <img src={qiitaImage} />
              <h4>Qiita</h4>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SNSPage